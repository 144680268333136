import { MULTIPLIER_APPROACH } from 'features/Allocations/constants/allocation.constants';

export enum ALLOCATION_FORM_STATE {
  DEFAULT = 'default',
  NEW = 'new',
  EDIT = 'edit',
  TEMPLATE = 'template',
  EDIT_TEMPLATE = 'editTemplate',
}

export enum ALLOCATION_DRAWER_TYPE {
  MASTER_ALLOCATION = 'masterAllocation',
  CAMPAIGN = 'campaign',
  MASTER_ITEM = 'masterItem',
}

export enum FORMULA_TYPES {
  WITH = 'formulaWith',
  WITHOUT = 'formulaWithout',
}

export enum FORMULA_OPERATORS_TYPES {
  AND = 'and',
  OR = 'or',
  NONE = 'none',
}

export enum ALLOCATION_RULE_OPERATOR {
  EQUALS = 'Equals',
  GREATER = 'Bigger',
  GREATER_EQUAL = 'BiggerOrEquals',
  LESS = 'Lower',
  LESS_EQUAL = 'LowerOrEquals',
}

export enum NUMERIC_RULE_TYPE {
  DEFAULT = 'default',
  VALUE = 'value',
  OPERATOR = 'operator',
}

export interface AllocationRule {
  id?: number;
  masterAllocationRuleGroupId?: number;
  attributeId: number | string | null;
  operator: ALLOCATION_RULE_OPERATOR;
  attributeModelId: string;
  attributeValue: string[] | string;
  isValueComparison: boolean;
  isPrimaryAttribute: boolean;
  isBroken?: boolean;
}

export interface AllocationFormula {
  id?: number;
  ruleGroups: AllocationRuleGroup[];
  total?: number;
  quantity?: number;
}

export interface AllocationRuleGroup {
  id?: number;
  masterAllocationFormulaId?: number;
  rules: AllocationRule[];
  quantity?: number;
}

export interface QuantityPerAttribute {
  attributeId: number;
  multiplier: number;
  isBroken?: boolean;
}

export interface QuantityPerTwoAttributes {
  numericAttributeId: number;
  percentageAttributeId: number;
  isNumericAttributeBroken?: boolean;
  isPercentageAttributeBroken?: boolean;
}

export interface Multiplier {
  quantityPerLocation?: number;
  quantityPerAttribute?: QuantityPerAttribute;
  quantityPerTwoAttributes?: QuantityPerTwoAttributes;
  multiplyingApproach: MULTIPLIER_APPROACH;
}

export interface MasterAllocationCreateRequest {
  clientId: number;
  name: string;
  formulaWith: AllocationFormula;
  formulaWithout: AllocationFormula;
  clientESTTimeStamp?: string;
  multiplier: Multiplier;
}

export interface MasterAllocationEditRequest
  extends MasterAllocationCreateRequest {
  id: number;
}

export interface AllocationRuleForm extends AllocationRule {
  formulaOperator: FORMULA_OPERATORS_TYPES;
}

export interface MasterAllocationCreateForm {
  clientId: number;
  name: string;
  formulaWith: AllocationRuleForm[];
  formulaWithout: AllocationRuleForm[];
  multiplier: Multiplier;
}

export interface Allocation extends MasterAllocationCreateRequest {
  id: number;
  businessId: string;
  createdAt: string;
  updatedAt: string;
  locationsQuantity: number;
  total: number;
  isBroken: boolean;
  result: AllocationResult[];
}

export interface AllocationResult {
  location: Location;
  total: number;
}
