import { Grid, theme, ThemeConfig } from 'antd';

const themeConfig: ThemeConfig = {
  token: {
    colorPrimaryBg: '#e6f4ff',
    colorPrimaryBgHover: '#bae0ff',
    colorPrimaryBorder: '#52C1EB',
    colorPrimaryBorderHover: '#1899D6',
    colorPrimaryHover: '#015DA7',
    colorPrimary: '#1D3D7B',
    colorPrimaryActive: '#0D2B51',
    colorPrimaryTextHover: '#003EB3',
    colorPrimaryText: '#002C8C',
    colorPrimaryTextActive: '#001D66',

    colorSuccessBg: '#F6FFED',
    colorSuccessBgHover: '#D9F7BE',
    colorSuccessBorder: '#B7EB8F',
    colorSuccessBorderHover: '#95DE64',
    colorSuccessHover: '#73D13D',
    colorSuccess: '#7DBD41',
    colorSuccessActive: '#389E0D',
    colorSuccessTextHover: '#237804',
    colorSuccessText: '#135200',
    colorSuccessTextActive: '#092B00',

    colorWarningBg: '#FFFBE6',
    colorWarningBgHover: '#FFF1B8',
    colorWarningBorder: '#FFE58F',
    colorWarningBorderHover: '#FFD666',
    colorWarningHover: '#FFC53D',
    colorWarning: '#FAAD14',
    colorWarningActive: '#D48806',
    colorWarningTextHover: '#AD6800',
    colorWarningText: '#874D00',
    colorWarningTextActive: '#613400',

    colorErrorBg: '#FFF1F0',
    colorErrorBgHover: '#FFCCC7',
    colorErrorBorder: '#FFA39E',
    colorErrorBorderHover: '#FF7875',
    colorErrorHover: '#FF7875',
    colorError: '#FF4D4F',
    colorErrorActive: '#CF1322',
    colorErrorTextHover: '#A8071A',
    colorErrorText: '#820014',
    colorErrorTextActive: '#5C0011',

    colorInfoBg: '#E6F4FF',
    colorInfoBgHover: '#BAE0FF',
    colorInfoBorder: '#52C1EB',
    colorInfoBorderHover: '#1899D6',
    colorInfoHover: '#015DA7',
    colorInfo: '#1D3D7B',
    colorInfoActive: '#0D2B51',
    colorInfoTextHover: '#003EB3',
    colorInfoText: '#002C8C',
    colorInfoTextActive: '#001D66',

    colorText: '#000000E0',
    colorTextHeading: '#000000E0',
    colorTextDescription: 'rgba(0,0,0,0.45)',
    colorTextPlaceholder: '#0000003F',
    colorTextDisabled: '#0000003F',
    colorIcon: '#00000072',
    colorIconHover: '#000000E0',

    colorBgContainer: '#FFFFFF',
    colorBgContainerDisabled: '#0000000A',
    colorBgLayout: '#F5F5F5',
    colorBgElevated: '#FFFFFF',
    controlItemBgHover: '#0000000A',
    controlItemBgActive: '#E6F4FF',
    controlItemBgActiveHover: '#BAE0FF',

    colorBorder: '#00000026',
    colorBorderSecondary: '#0000000F',
    colorSplit: '#0000000F',

    fontFamily: 'area-normal, Rubik, sans-serif',
  },
  components: {
    Layout: {
      headerBg: '#FFFFFF',
      headerHeight: 46,
    },
    Collapse: {
      headerPadding: 0,
    },
  },
};

export const { useToken } = theme;
export const { useBreakpoint } = Grid;

export default themeConfig;
