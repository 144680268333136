import { ResultStatusType } from 'antd/es/result';

export interface ErrorDescriptions {
  message: string;
  statusCode: ResultStatusType;
  title: string;
}

export interface ServerError {
  status: number;
  data: {
    error: {
      type: SERVER_ERROR_TYPE;
      title: string;
      message: string;
      affectedFields: FormError[];
      affectedEntities: AffectedEntities[];
    };
    success: boolean;
  };
}

export interface AffectedEntities {
  affectedItems: AffectedEntities[];
  id: number;
  name: string;
}

export interface FormError {
  name: string[];
  errors: string[];
}

export enum SERVER_ERROR_TYPE {
  CONFLICT = 'Conflict',
  ERROR = 'Error',
  WARNING = 'Warning',
  FIELDS_ERRORS = 'FieldsErrors',
}

export enum RESPONSE_TYPE {
  SUCCESS = 'Success',
  ERROR = 'Error',
  WARNING = 'Warning',
}
